<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Cari Register</strong>
        <a-input class="ml-4" placeholder="Ketik Nama" v-model="input.nama" style="width: 200px;" @keydown.enter="findData"></a-input>
        <a-input class="ml-2" placeholder="Ketik Alamat" v-model="input.alamat" style="width: 200px;" @keydown.enter="findData"></a-input>
        <a-select style="width: 70px;" placeholder="Kode" class="ml-2" v-model="input.cabang">
          <a-select-option value="01">01</a-select-option>
        </a-select>
        <!-- <a-input class="ml-2" placeholder="Ketik Cabang" v-model="input.cabang" @keydown.enter="findData"></a-input> -->
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label v-if="table.tableatas.datatable.length !== null && table.tableatas.datatable.length !== 0" class="text-primary" style="margin-left: .5rem; margin-top: .5rem; margin-bottom: unset !important;">Jumlah Data: {{ table.tableatas.datatable.length }}</label>
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.tableatas.column"
            :dataSource="table.tableatas.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
          </a-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <label v-if="table.tablebawah.datatable.length !== null && table.tablebawah.datatable.length !== 0" class="text-primary" style="margin-left: .5rem; margin-top: .5rem; margin-bottom: unset !important;">Jumlah Data: {{ table.tablebawah.datatable.length }}</label>
          <a-table
            :scroll="{ y: 200 }"
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <span slot="saldo" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          </a-table>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  data() {
    return {
      input: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              // width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              // width: 100,
            },
            {
              title: 'KTP',
              dataIndex: 'ktp',
              scopedSlots: { customRender: 'ktp' },
              // width: 100,
            },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              align: 'right',
              // width: 100,
            },
          ],
          datatable: [],
        },
      },
      loading: false,
    }
  },
  methods: {
    moment,
    async findData() {
      var imbuhan = ''
      imbuhan += this.input.nama !== '' ? '?Nama=' + this.input.nama : ''
      imbuhan += this.input.alamat !== '' ? this.input.nama !== '' ? '&Alamat=' + this.input.alamat : '?Alamat=' + this.input.alamat : ''
      imbuhan += this.input.cabang !== '' ? '&cabang=' + this.input.cabang : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      if (!this.loading) {
        this.loading = true
        var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
        if (res) {
          this.table.tablebawah.datatable = res.data
          this.loading = false
        }
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedRekening = record.rekening
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>
